import EventEmitter from 'o365.modules.EventEmitter.ts';

export class GlobalMessageListener {
    private _eventEmitter: EventEmitter<{'message': any}>;
    constructor() {
        this._eventEmitter = new EventEmitter();
        window.self.addEventListener('message', this._onMessage.bind(this));
    }

    on<T>(pListener: ListenerFunction<T> ) {
        return this._eventEmitter.on('message', pListener);
    }

    off<T>(pListener: ListenerFunction<T>) {
        this._eventEmitter.off('message', pListener);
    }

    removeAllListeners() {
        this._eventEmitter.removeAllListeners();
    }

    private _onMessage(event: MessageEvent) {
        try {
            const payload: MessagePayload<any> = JSON.parse(event.data);
            this._eventEmitter.emit('message', payload.message, payload.type, payload.meta);
        } catch (ex) {
            // fail silently
            // console.error(ex);
        }
    }
}

type ListenerFunction<T> = (pMessage: T, pType: string | undefined, pMeta?: MessagePayload<T>['meta']) => void;
export type MessageType = undefined | 'dataobject';
export type MessagePayload<T> = { 
    type: MessageType;
    message: T;
    meta?: {
        dataObjectId?: string;
        appId?: string;
    }
 }

const globalMessageListener = new GlobalMessageListener();

export default globalMessageListener;